import tg from "utils/tg";
import Tag from 'types/tag'
const tg_id = tg.initDataUnsafe.user?.id;

export default async ():Promise<Tag[] | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "tags";
    API_URL.searchParams.set("tg_id", String(tg_id));

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    return null
  }
};
