import React, { useEffect, useRef, useState } from "react";
import "./VideoTask.css";
import PageWrapper from "UI/PageWrapper";
import VideoTaskDetails from "./components/VideoTaskDetails";
import VideoTaskQuestion from "./components/VideoTaskQuestion";
import VideoTaskReward from "./components/VideoTaskReward";
import { useLocation, Location, useNavigate } from "react-router-dom";
import Task from "types/task";
import doneVideoTask from "api/done-video-task";
import VideoTaskStatus from "./components/VideoTaskStatus";
type Props = {};

const VideoTask = (props: Props) => {
  const [opened, setopened] = useState(false);
  const [loading, setloading] = useState(false);
  const [answer, setanswer] = useState<null | boolean>(null);
  const [result, setresult] = useState(false)
  const navigate = useNavigate()
  const { state: task } = useLocation() as Location<Task | undefined>;
  const [bid, setBid] = useState<number>((task?.bid_min || 0));
  useEffect(() => {
    if(!task) return
    const seconds = task.status === 0 ? Number(task?.duration) * 1000 : 0
    setTimeout(() => setopened(true), seconds);
  }, []);
  useEffect(() => {
    if(answer === null || !task) return
    doneVideoTask(task.id,answer,bid).then(res => {
      if (res) {
        setresult(true)
      }
    })
    
  }, [answer])
  return (
    <PageWrapper className="video-task-page">
      {
          task?.status === 0
          ? <VideoTaskDetails rep={Number(task?.reward) || 0} min_bid={(task?.bid_min || 0)} bid={bid} setBid={setBid}/>
          : task && <VideoTaskStatus task={task}/>
      }
      <div
        className="iframe-container"
        dangerouslySetInnerHTML={{ __html: task?.iframe_video || testVideo }}
      />
      <VideoTaskQuestion disabled={task?.status !== 0} opened={opened} handleQuestion={setanswer} loading={loading} setloading={setloading} answer={answer}/>
      {(result && answer !== null) && (
        <VideoTaskReward
          answer={answer}
          TaskReward={35}
          close={() => setTimeout(() => navigate('/main/tasks'), 500)}
        />
      )}
    </PageWrapper>
  );
};

const testVideo = `
<iframe
  ref={iframeRef}
  width="100%"
  height="100%"
  src="https://rutube.ru/play/embed/5b8160fb29edfb3b8043f0e462671d44/"
  frameBorder="0"
  allow="clipboard-write; autoplay"
  allowFullScreen
></iframe>`;

export default VideoTask;
