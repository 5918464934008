import React from 'react'
import { useNavigate } from 'react-router-dom'
import Task from 'types/task'
import Icon from 'UI/Icon'
import TaskTimer from 'UI/TaskTimer'

type Props = {
    task:Task
}

const VideoTaskStatus = ({task}: Props) => {
    const navigate = useNavigate()
  return (
    <div className='video-task-info pending'>
        <TaskTimer seconds={Number(task.seconds)}/>
        <button className='close-video-task' onClick={() => navigate('/main/tasks')}>
            <Icon icon='close'/>
        </button>
    </div>
  )
}

export default VideoTaskStatus