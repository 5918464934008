import { motion } from "framer-motion";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Icon from "UI/Icon";

type Props = {
  opened: boolean;
  handleQuestion:(answer:boolean) => void
  loading:boolean
  answer:boolean | null
  setloading:Dispatch<SetStateAction<boolean>>
  disabled?:boolean
};

const VideoTaskQuestion = ({ opened ,handleQuestion,loading,answer,setloading,disabled}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  
  const [bottom, setBottom] = useState(0);
  useEffect(() => {
    if (ref.current) {
      const { height } = ref.current.getBoundingClientRect();
      setBottom(height - 35);
    }
  }, []);
  const handleAnswer = (a:boolean) => {
    if(disabled) return
    handleQuestion(a)
    setloading(true)
  } 
  return (
    <motion.div
      animate={{
        height: opened ? 35 : 0,
        overflow:opened ? 'auto' : 'hidden'
    }}
      className="video-task-question-container"
    >
      <motion.div
        initial={{ y: opened ? 0 : "100%" }}
        animate={{ y: opened ? 0 : "100%" }}
        className="video-task-question"
      >
        <motion.div
          drag="y"
          ref={ref}
          animate={{y:opened ? -bottom : 10}}
          dragConstraints={{ top: -bottom, bottom: 0 }}
          className="video-task-question-body"
        >
          <hr />
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut{" "}
          </p>
          <div className={`video-task-question-buttons ${disabled && 'disabled'}`}>
            <button onClick={() => handleAnswer(true)}>{answer && loading ? <Icon icon="loading"/> : 'Y'}</button>
            <button onClick={() => handleAnswer(false)}>{!answer && loading ? <Icon icon="loading"/> : 'N'}</button>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default VideoTaskQuestion;
