import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from 'UI/PageWrapper'
import './tasks.css'
import getTasks from 'api/get-tasks'
import TaskModal from './TaskModal/TaskModal'
import Task from 'types/task'
import { AnimatePresence, motion } from 'framer-motion'
import formatNum from 'utils/format-num'
import Img from 'UI/Img'
import Icon from 'UI/Icon'
import AppContext from 'utils/app-context'
import { useNavigate } from 'react-router-dom'
import TaskTimer from '../../../../UI/TaskTimer'

const Tasks = () => {
    const [tasks, settasks] = useState<Task[] | null>(null)
    console.log("🚀 ~ Tasks ~ tasks:", tasks)
    const navigate = useNavigate()
    const [openedTask, setopenedTask] = useState<Task | null>(null)
    const [showComplited, setshowComplited] = useState(false)
    const {t} = useContext(AppContext)
    const text = t('tasksPage')
    const updateTasks = async () => {
        const result = await getTasks()
        if (result) settasks(result)
    }

    useEffect(() => {
        updateTasks()
    }, [])
    console.log(tasks?.filter(({status}) => status > 0).length);
    
  return (
    <PageWrapper>
        <div className="tasks-screen">
        <h2 className="tasks-title-h2">{text.title}</h2>
        <div className="tasks-types">
            <div className={`active ${showComplited && 'type-desactive'}`} onClick={() => setshowComplited(false)}>{text.active}</div>
            <div className={`completed ${!showComplited && 'type-desactive'}`} onClick={() => setshowComplited(true)}>{text.completed}</div>
        </div>
            <div className='tasks-items tasks-items-active'>
                <AnimatePresence mode='wait'>
            <motion.div
                key={Number(showComplited)}
                initial={{opacity:0,translateX:50}}
                animate={{opacity:1,translateX:0}}
                transition={{duration:0.1}}
                exit={{opacity:0,translateX:-50}}
                style={{display:'flex',flexDirection:'column',rowGap:14}}
            >
                {(showComplited && tasks?.filter(({status}) => status !== 1).length === 0) && <p>{text.allCompleted}</p>}
                {/* {(!showComplited && tasks?.filter(({status}) => status === 1).length === 0) && <p>There are no completed tasks</p>} */}
                {tasks ? tasks.map((task) => {
                    if (showComplited && task.status !== 1 && !task.is_video) return null
                    else if (!showComplited && (task.is_video && task.status !== 1)) return null
                    const {id,title,reward,icon,subtitle,reward_type,is_gold_task,is_video,tag_title,seconds} = task
                    return (
                        <div
                            className={Number(is_gold_task) ? "tasks-item" : 'completed-item'}
                            key={id}
                            onClick={() => Number(is_video) ? navigate('/main/video-task',{state:task}) : setopenedTask(task)}
                        >
                            <Img src={Number(is_video) ? '/img/tasks/video.svg' : icon} size={33}/>
                        <div className="tasks-content">
                            <div className="tasks-title">
                            {title}
                            </div>
                            <div className="tasks-subtitle">
                                {subtitle}
                            </div>
                            <div className="tasks-rep">
                                {reward_type === 'balance'
                                ? <>
                                    <img src="/img/coin4.png"/>
                                    + {formatNum(reward)} 
                                </>
                                : <>
                                <span className="big-green">REP</span>
                                + {formatNum(reward)} points
                                </>
                            } 
                            </div>
                        </div>
                        {!!Number(is_video) &&
                            <div className='task-video-attrs'>
                                <span className='task-video-tag'>{tag_title}</span>
                                <TaskTimer seconds={Number(seconds)}/>
                            </div>
                        }
                        <div className="tasks-arrow">
                            <TaskIcon status={task.status}/>
                        </div>
                    </div>
                    )
                }) : <Icon icon='loading'/>}
            </motion.div>
        </AnimatePresence>
            </div>
        </div>
        <TaskModal task={openedTask} openedTask={setopenedTask} refreshTasks={updateTasks}/>
    </PageWrapper>
  )
}

const TaskIcon = ({status}:{status:Task['status']}) => {
    let icon

    switch (status) {
        case 0:
            icon = (
                <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.25 23L18.75 15.5L11.25 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            )
            break;
    
            case 1:
                icon = (<svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.25 15.5L13.75 18L18.75 13M27.5 15.5C27.5 22.4036 21.9036 28 15 28C8.09644 28 2.5 22.4036 2.5 15.5C2.5 8.59644 8.09644 3 15 3C21.9036 3 27.5 8.59644 27.5 15.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>)
                break
            case 2:
                icon = (
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 6.5V14H19.625M26.5 14C26.5 20.9036 20.9036 26.5 14 26.5C7.09644 26.5 1.5 20.9036 1.5 14C1.5 7.09644 7.09644 1.5 14 1.5C20.9036 1.5 26.5 7.09644 26.5 14Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                )
            break

    }

    return icon
} 

export default Tasks