import claimFarming from 'api/claim-farming'
import startFarming from 'api/start-farming'
import React, { CSSProperties, RefObject, useContext, useEffect, useRef, useState } from 'react'
import UserData from 'types/user-data'
import AppContext from 'utils/app-context'
import getCharacterStyle from 'utils/get-character'
import tg from 'utils/tg'
import { MainContext } from '../../../../../App'
import useRewardAnimation, { RewardAnimationContext } from 'hooks/use-reward-animation'
import formatNum from 'utils/format-num'
import Icon from 'UI/Icon'
import './FarmingButton.css'
import { useNavigate } from 'react-router-dom'
type Props = {
    userData:UserData
}

type FarmingStateProps = {
    farming_coins:number,
    farming_percent:number,
    farming_seconds:number,
    dots:boolean,
}

const FarmingButton = ({userData}: Props) => {
    const {user,updateUserInfo,t} = useContext(AppContext)
    const errors = t('errors')
    const text = t('homeScreen')
    const {farming_coins,farming_percent,farming_seconds,farming_status,mining_interval,mining_per_interval,level} = userData
    const navigate = useNavigate()
    const dots = true
    const [farmingState,setFarmingState] = useState<null | FarmingStateProps>({farming_coins,farming_percent,farming_seconds,dots})
    const {setNotification} = useContext(MainContext)
    const hours = Math.floor((farmingState?.farming_seconds || 0) / 3600)
    const seconds = Math.floor(((farmingState?.farming_seconds || 0) - (hours * 3600)) / 60)

    const {setConfig,reward,startTargetRefAnimation,startAnimation} = useContext(RewardAnimationContext)

    const handleComingSoon:React.MouseEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation()
        if(!user) return
        const heroText = localStorage.getItem('heroText')
        if(heroText !== user.pers_alert_text) {
            localStorage.removeItem('heroClick')
            localStorage.setItem('heroText',user.pers_alert_text)
        }

        const showCount = Number(localStorage.getItem('heroClick')) || 0
        if(showCount >= 3) return
        
        localStorage.setItem('heroClick',String(showCount+1))
        setNotification({
          id:'',
          text:user.pers_alert_text,
          user_id:'0',
      })
    }
    const {character,backgroundFog} = getCharacterStyle(level)
    const handleFarming = async () => {
        tg.HapticFeedback.impactOccurred('medium')
        let res
        switch (farming_status) {
            case 0:
                res = await startFarming()    
                if (res?.result && user) {
                    await updateUserInfo()
                    setFarmingState({...user,dots:true})
                }
                else tg.showAlert(errors.startFarming)
            break;
        
             case 2:
                res = await claimFarming()    
                if (res?.result) {
                    setConfig({
                        rewardValue:(farmingState?.farming_coins || 0),
                        balanceValue:(user?.balance || 0),
                        onEndAnimation:() => {
                            updateUserInfo()
                            if(user) setFarmingState(null)
                        }
                    })
                    startAnimation()
                } else tg.showAlert(errors.claimFarming)
            break;
        
            default:
                break;
        }
    }

    useEffect(() => {
        if (!farmingState || user?.farming_status !== 1) return
        if (farmingState?.farming_seconds > 0) {
            const timeout = setTimeout(() => {
                // Обновляем состояние
                const newFarmingState = { ...farmingState };

                // Уменьшаем количество оставшихся секунд
                newFarmingState.farming_seconds-=0.25;

                // Пересчитываем прогресс в процентах
                newFarmingState.farming_percent = ((mining_interval - newFarmingState.farming_seconds) / mining_interval) * 100;

                // Пересчитываем количество нафармленных монет в зависимости от процента
                newFarmingState.farming_coins = ((newFarmingState.farming_percent / 100) * mining_per_interval)

                const afterDot:string | undefined = String(newFarmingState.farming_seconds).split('.')[1]
                
                if (!afterDot || afterDot === '5') {
                    newFarmingState.dots = !newFarmingState.dots
                }
                // Обновляем состояние
                setFarmingState(newFarmingState);
            }, 250);

            return () => clearTimeout(timeout);
        } else {
            updateUserInfo()
        }
    }, [farmingState]);
    useEffect(() => {
        if(user) setFarmingState({...user,dots:true})
    }, [user])
    return (
    <>
    <button className='home-page-video-task' onClick={() => navigate('/main/tasks')}>
        <div className="video-task-reward">
            <img src="/img/coin4.png" alt="" />
            <span>+ {formatNum(9999)}</span>
        </div>
        <span className='video-task-text'>claim</span>
        <div className="video-task-type">
            <span>Video</span>
            <Icon icon='video'/>
        </div>
    </button>
     {!farming_status &&
        <div onClick={handleFarming} className="farming-start" style={{zIndex:3,display:'flex',justifyContent:'space-between'}}>
            <div className="farming-reward hideEL">
            <img src="/img/coin4.png" alt="" />
            <div className="farming-reward-count" style={{textAlign:'left'}}>+ {userData.mining_per_hour.toFixed(2)} / {text.hours}</div>
            </div>
            <p style={{textTransform:'uppercase'}}>{text.start}</p>
            <p className="farming-reward-count">{formatSeconds(mining_interval)}h</p>
        </div>
    }
      <div onClick={handleFarming} className="farming">
        <div className="farming-line-container">
            <div className="farming-line" style={{width:`${farmingState?.farming_percent}%`}}></div>
        </div>
        <div className="farming-reward hideEL">
          <img src="/img/coin4.png" ref={startTargetRefAnimation as RefObject<HTMLImageElement>} />
          <div className="farming-reward-count" style={{textAlign:'left'}}>+{
            farming_status === 2
            ?  reward.toFixed(2)
            :  farmingState?.farming_coins.toFixed(4)
        }</div>
        </div>
        {
           farming_status === 1 && 
           <>
            <p className="farming-text hideEL" style={{width:66,textAlign:'left'}}>{text.farming}</p>
            <p className="farming-hour hideEL">
                <span id="seconds">{hours.toString().padStart(2, '0')}</span>
                <span style={{opacity:Number(farmingState?.dots)}}>:</span>
                <span id="milliseconds">{seconds.toString().padStart(2, '0')}</span>
                {text.hours}
            </p>
           </>
        }
        {farming_status === 2 && <p className="farming-claim hideEL" style={{display:'block'}}>{text.claim}</p>}
        {backgroundFog}
        {character}
        {user?.products.map(({image}) => (
            <img src={`/img/layers/${image}`} className="farming-img"/>
        ))}
        <div className="farming-img farming-click-area" onClick={handleComingSoon}></div>
      </div></>
  )
}
function formatSeconds(seconds: number): string {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds - (hours * 3600)) / 60)

    const formattedMinutes = hours.toString().padStart(2, '0')
    const formattedSeconds = minutes.toString().padStart(2, '0')

    return `${formattedMinutes}:${formattedSeconds}`;
}

export default FarmingButton