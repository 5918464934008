export default function formatTime(seconds: number, dots: boolean): string {
  const hours: number = Math.floor(seconds / 3600);
  const minutes: number = Math.floor((seconds % 3600) / 60);
  const remainingSeconds: number = seconds % 60;

  const timeParts: string[] = [];

  if (hours > 0) {
    timeParts.push(String(hours).padStart(2, "0"));
  }

  if (minutes > 0 || hours > 0) {
    timeParts.push(String(minutes).padStart(2, "0"));
  }

  timeParts.push(String(remainingSeconds).padStart(2, "0"));

  return timeParts.join(dots ? ":" : " ");
}
